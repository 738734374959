import { Component, OnInit } from '@angular/core';




export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;

}

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'nc-bank', class: '' },
  { path: '/admin', title: 'Admins', icon: 'nc-circle-10', class: '' },
  { path: '/employee', title: 'Employees', icon: 'nc-single-02', class: '' },
  { path: '/recruiter', title: 'Recruiters', icon: 'nc-single-02', class: '' },
  { path: '/resume', title: 'Resumes', icon: 'nc-single-02', class: '' },
  { path: '/country', title: 'Countries', icon: 'nc-world-2', class: '' },
  { path: '/domain', title: 'Domains / Categories', icon: 'nc-world-2', class: '' },
  { path: '/settings', title: 'Settings', icon: 'nc-settings', class: '' },

];

@Component({
  moduleId: module.id,
  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {




  constructor(


  ) {
  }

  public menuItems: any[];
  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);

    console.log(this.menuItems);
  }



}
